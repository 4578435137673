import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Heading extends Component {
  render() {
    return (
      <h1 className="heading text-center pt-5 mb-5">{this.props.children}</h1>
    );
  }
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
};
