import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const text = require('../constants/text');

export default class Home extends Component {
  render() {
    return (
      <div className="home">
        <div className="d-flex h-100 align-items-center justify-content-center text-center">
          <div>
            <h1 className="home__heading text-uppercase">Orr &amp; Associates</h1>
            <h2 className="home__subheading">
              {text.home_subheading}
            </h2>
          </div>
        </div>
        <div className="home__footer">
          <div className="home__social">
            <a
              className="home__social-link"
              href="https://www.facebook.com/pages/Orr-Associates/520175278065542"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook" />
            </a>
            <a
              className="home__social-link"
              href="https://twitter.com/orrbodies"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter-square" />
            </a>
            <a
              className="home__social-link"
              href="https://au.linkedin.com/in/thomas-orr-a7767813/pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin" />
            </a>
          </div>
          <span className="home__copyright">
            <i className="far fa-copyright" />{' '}
              Orr &amp; Associates {new Date().getFullYear()}
            {' | '}
            <Link to="/privacy/" className="text-white">Privacy Policy</Link>
            {' | '}
            <Link to="/legal/" className="text-white">Legal</Link>
          </span>
        </div>
      </div>
    );
  }
}
