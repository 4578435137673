import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Filters from './Filters';
import ResourcePreview from './ResourcePreview';
import Heading from './Heading';

export default class Resources extends Component {
  state = {
    resources: [],
    filters: {
      types: [],
      type: null,
      keyword: '',
    },
    loading: true,
  }
  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/resources`,
    })
      .then((response) => {
        const types = [];
        const resources = response.data;
        resources.forEach((resource) => {
          if (types.indexOf(resource.type) < 0) {
            types.push(resource.type);
          }
        });
        let keyword = '';
        if (this.props.location.search.indexOf('?search') === 0) {
          keyword = decodeURIComponent(this.props.location.search.replace('?search=', ''));
        }
        const filters = {
          types,
          type: null,
          keyword,
        };
        this.setState({
          resources,
          filters,
          loading: false,
        });
      });
  }
  render() {
    const list = [];
    const itemListElement = [];
    const filteredResources = this.state.resources
      .filter((resource) => {
        return this.state.filters.type === resource.type || this.state.filters.type === null;
      })
      .filter((resource) => {
        const keyword = this.state.filters.keyword.toLowerCase();
        return resource.title.toLowerCase().indexOf(keyword) >= 0
          || (resource.description && resource.description.toLowerCase().indexOf(keyword) >= 0)
          || keyword.length === 0;
      });
    if (filteredResources.length === 0 && this.state.filters.keyword.length) {
      ReactGA.event({
        category: 'Search',
        action: 'Fail',
        label: this.state.filters.keyword,
      });
    }
    filteredResources.forEach((resource, index) => {
      list.push((
        <ResourcePreview
          key={resource.alias}
          resource={resource}
          className="m-3 border rounded"
        />
      ));
      itemListElement.push({
        '@type': 'ListItem',
        position: index + 1,
        url: `${window.location.origin}/resource${resource.alias}/`,
      });
    });
    return (
      <div className="resources container-fluid">
        <Heading>Resources</Heading>
        {!this.state.loading &&
          <div className="pb-5 text-center">
            <Filters
              filters={this.state.filters}
              onChange={(filters) => {
                this.setState({ filters });
              }}
              onBlur={() => {
                if (filteredResources.length
                  && this.state.filters.keyword.length
                ) {
                  ReactGA.event({
                    category: 'Search',
                    action: 'Success',
                    label: this.state.filters.keyword,
                  });
                }
              }}
            />
          </div>
        }
        <div className="d-flex flex-row m-3 flex-wrap justify-content-center">
          {this.state.loading && <h3>Loading...</h3>}
          {list}
          {list.length === 0 && !this.state.loading && <h3>No results found</h3>}
        </div>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'ItemList',
              itemListElement,
            }),
          }}
        />
      </div>
    );
  }
}

Resources.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};
