import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import $ from 'jquery';

export default class Subscribe extends Component {
  state = {
    hasSubscribed: false,
    errors: null,
    loading: false,
  }

  componentDidMount() {
    $(this.modal).modal({ show: false });
    $(this.modal).on('hidden.bs.modal', () => {
      this.setState({
        hasSubscribed: false,
        errors: null,
      });
    });
  }

  open = () => {
    $(this.modal).modal('show');
  }

  subscribe = () => {
    this.setState({ loading: true });
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/subscribe`,
      data: { email: this.email.value },
    })
      .then((response) => {
        if (!response.data.errors) {
          this.setState({ hasSubscribed: true, errors: null, loading: false });
          ReactGA.event({
            category: 'Mailing List',
            action: 'Subscribed',
          });
        } else {
          this.setState({ errors: response.data.errors });
        }
      });
  }

  render() {
    return (
      <div className={this.props.className}>
        <button
          className="btn btn-outline-info my-2 my-sm-0"
          type="submit"
          onClick={this.open}
        >
          Mailing List
        </button>
        <div
          ref={(e) => { this.modal = e; }}
          className="modal"
          tabIndex={-1}
          role="dialog"
        >
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Join our mailing list</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.errors && <div>{this.state.errors.join(', ')}</div>}
                {this.state.hasSubscribed ?
                  <div>Thanks for subscribing!</div>
                  :
                  <div>
                    <p>Want to be kept up to date with what we are doing?</p>
                    <input
                      type="email"
                      placeholder="email@example.com"
                      className="form-control"
                      ref={(e) => { this.email = e; }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.subscribe(e);
                        }
                      }}
                    />
                  </div>
                }
              </div>
              <div className="modal-footer">
                {this.state.hasSubscribed ?
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    data-dismiss="modal"
                    aria-label="Close"
                    key="close"
                  >
                    Close
                  </button>
                :
                  <button
                    className="btn btn-outline-info"
                    onClick={this.subscribe}
                    disabled={this.state.loading}
                    key="subscribe"
                  >
                    Subscribe
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Subscribe.propTypes = {
  className: PropTypes.string,
};

Subscribe.defaultProps = {
  className: '',
};
