import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDisqusComments from 'react-disqus-comments';
import axios from 'axios';
import ReactGA from 'react-ga';
import Heading from './Heading';
import NoMatch from './NoMatch';
import ImageModal from './ImageModal';

export default class Tutorial extends Component {
  state = {
    tutorial: null,
    noMatch: false,
    imageModalOpen: false,
  }
  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/tutorial/${this.props.match.params.alias}`,
    })
      .then((response) => {
        document.title = `${response.data.title} | Tutorial | Orr & Associates`;
        this.setState({ tutorial: response.data });
      })
      .catch(() => {
        this.setState({ noMatch: true });
      });
  }
  render() {
    const tutorial = this.state.tutorial;
    if (this.state.noMatch) {
      return (<NoMatch />);
    } else if (!tutorial) {
      return null;
    }
    return (
      <div className="resource container">
        {this.state.imageModalOpen &&
          <ImageModal
            onClose={() => {
              this.setState({ imageModalOpen: false });
            }}
            image={tutorial.image}
            name={tutorial.title}
          />
        }
        <Heading>{tutorial.title}</Heading>
        <div className="row pb-5">
          <div className="col-sm-6">
            <h2 className="pt-3">Overview</h2>
            <p className="lead">{tutorial.description}</p>
            <p className="font-weight-bold">
              Tutorial by {tutorial.author}
              <br />
              Last edited on {tutorial.modified}
            </p>
          </div>
          <div className="col-sm-6">
            <img
              src={tutorial.image.medium}
              alt={`Preview of ${tutorial.title}`}
              className="resource__preview img-thumbnail"
              onClick={() => {
                this.setState({ imageModalOpen: true });
                ReactGA.event({
                  category: 'Tutorial',
                  action: 'Preview',
                  label: tutorial.title,
                });
              }}
              title={`Preview of ${tutorial.title}`}
            />
          </div>
          <div
            className="resource__description"
            dangerouslySetInnerHTML={{
              __html: tutorial.content ? tutorial.content.replace(/\/sites\/default\/files/g, `${process.env.REACT_APP_BACKEND_URL}/sites/default/files`) : '',
            }}
          />
        </div>
        <hr />
        <ReactDisqusComments
          shortname={process.env.REACT_APP_DISQUS_SHORTNAME}
          identifier={tutorial.id}
          className="pt-5"
          url={`${window.location.protocol}//${window.location.host}/${this.props.match.params.alias}/`}
          title={tutorial.title}
          onNewComment={() => {
            ReactGA.event({
              category: 'Tutorial',
              action: 'Comment',
              label: tutorial.title,
            });
          }}
        />
      </div>
    );
  }
}

Tutorial.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
