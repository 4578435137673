import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import TutorialPreview from './TutorialPreview';
import Heading from './Heading';

export default class Tutorials extends Component {
  state = {
    tutorials: [],
    loading: true,
  }
  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/tutorials`,
    })
      .then((response) => {
        const types = [];
        const tutorials = response.data;
        tutorials.forEach((tutorial) => {
          if (types.indexOf(tutorial.type) < 0) {
            types.push(tutorial.type);
          }
        });
        this.setState({
          tutorials,
          loading: false,
        });
      });
  }
  render() {
    const list = [];
    const itemListElement = [];
    this.state.tutorials.forEach((tutorial, index) => {
      list.push((
        <TutorialPreview
          key={tutorial.alias}
          tutorial={tutorial}
          className="m-3 border rounded"
        />
      ));
      itemListElement.push({
        '@type': 'ListItem',
        position: index + 1,
        url: `${window.location.origin}/tutorial${tutorial.alias}/`,
      });
    });
    return (
      <div className="resources container-fluid">
        <Heading>Tutorials</Heading>
        <div className="d-flex flex-row m-3 flex-wrap justify-content-center">
          {this.state.loading && <h3>Loading...</h3>}
          {list}
          {list.length === 0 && !this.state.loading && <h3>Watch this space! There will be some handy tutorials here soon!!</h3>}
        </div>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'ItemList',
              itemListElement,
            }),
          }}
        />
      </div>
    );
  }
}

Tutorials.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};
