import React, { Component } from 'react';
import classNames from 'classnames';
import Heading from './Heading';

export default class ZoomCalculator extends Component {
  state = {
    field: 'height',
    value: 60,
    aspectRatio: 4 / 3,
    focalLength: 3.61,
    sensorWidth: 6.16,
    resolution: 12000000,
  }  
  render() {
    const imageWidthInPixels = Math.sqrt(this.state.resolution * this.state.aspectRatio);
    const m = this.state.sensorWidth / (this.state.focalLength * imageWidthInPixels); // GSD at height of 1m.
    const circumferenceOfEarth = 40075008;
    const values = {};
    switch (this.state.field) {
      case 'gsd':
        values.gsd = this.state.value;
        values.height = values.gsd / m;
        values.zoom = Math.log2((circumferenceOfEarth / 256) / values.gsd);
        break;

      case 'zoom':
        values.zoom = this.state.value;
        values.gsd = (circumferenceOfEarth / 256) / ( 2 ** values.zoom);
        values.height = values.gsd / m;
        break;

      case 'height':
        values.height = this.state.value;
        values.gsd = m * values.height;
        values.zoom = Math.log2((circumferenceOfEarth / 256) / values.gsd);
        break;
    }
    return (
      <div className="container">        
        <Heading>Zoom Calculator</Heading>
        <div className="row">
          <div className="col-8 offset-2">
            <div>
              This tool can help determine:
              <ul>
                <li>The zoom level to use when creating tiles.</li>
                <li>The height to fly a drone to achieve a desired GSD.</li>
              </ul>
            </div>
            <h2>Camera properties</h2>
            <div className="form-group row">
              <label className="col-3 col-form-label">Aspect ratio</label>
              <div className="col-9">
                <select 
                  className={classNames('form-control')}
                  type="number"
                  value={this.state.aspectRatio} 
                  onChange={e => this.setState({ aspectRatio: parseFloat(e.target.value) })}
                >
                  <option value={4 / 3}>
                    4:3
                  </option>
                  <option value={16 / 9}>
                    16:9
                  </option>
                </select>
              </div>
            </div> 
            <div className="form-group row">
              <label className="col-3 col-form-label">Resolution (MP)</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={this.state.resolution / 1000000}
                  onChange={e => this.setState({ resolution: parseInt(e.target.value) * 1000000 })}
                />       
              </div>
            </div> 
            <div className="form-group row">
              <label className="col-3 col-form-label">Focal length (mm)</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={this.state.focalLength} 
                  onChange={e => this.setState({ focalLength: parseFloat(e.target.value) })}
                />       
              </div>
            </div> 
            <div className="form-group row">
              <label className="col-3 col-form-label">Sensor width (mm)</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={this.state.sensorWidth} 
                  onChange={e => this.setState({ sensorWidth: parseFloat(e.target.value) })}
                />       
              </div>
            </div> 
            <h2>Calculations</h2>
            <div className="form-group row">
              <label className="col-3 col-form-label">GSD (m)</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={values.gsd} 
                  step="0.0001"
                  onChange={e => this.setState({ field: 'gsd', value: parseFloat(e.target.value) })}
                />       
              </div>
            </div>   
            <div className="form-group row">
              <label className="col-3 col-form-label">Zoom level</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={values.zoom} 
                  step="0.01"
                  onChange={e => this.setState({ field: 'zoom', value: parseFloat(e.target.value) })}
                />       
              </div>
            </div>     
            <div className="form-group row">
              <label className="col-3 col-form-label">Height (m)</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={values.height} 
                  step="0.01"
                  onChange={e => this.setState({ field: 'height', value: parseFloat(e.target.value) })}
                />       
              </div>
            </div>          
          </div>              
        </div>              
      </div>
    );
  }
}