import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Filters extends Component {
  render() {
    const typeFilter = this.props.filters.types.map((type) => {
      const isActive = type === this.props.filters.type;
      return (
        <a
          key={type}
          className={classNames('dropdown-item', {
            active: isActive,
          })}
          onClick={() => {
            const filters = { ...this.props.filters };
            filters.type = type;
            this.props.onChange(filters);
          }}
        >
          {type}
        </a>
      );
    });
    return (
      <div className="filters form-inline">
        <div className="input-group bg-white">
          <input
            type="text"
            className="form-control"
            aria-label="Text input with dropdown button"
            placeholder="search"
            value={this.props.filters.keyword}
            onChange={(e) => {
              e.preventDefault();
              const filters = { ...this.props.filters };
              filters.keyword = e.target.value;
              this.props.onChange(filters);
            }}
            onBlur={this.props.onBlur}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Filter
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                onClick={() => {
                  const filters = { ...this.props.filters };
                  filters.type = null;
                  this.props.onChange(filters);
                }}
              >
                All
              </a>
              <div role="separator" className="dropdown-divider" />
              {typeFilter}
            </div>
          </div>
        </div>

      </div>
    );
  }
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
