import React, { Component } from 'react';

export default class NoMatch extends Component {
  render() {
    return (
      <article className="container">
        <header>
          <h1 className="heading text-center pt-5 mb-5">Oops, we could not find what you are after...</h1>
        </header>
        <div>
          <p className="text-center">
            <i className="fa fa-5x fa-thumbs-down" />
          </p>
        </div>
      </article>
    );
  }
}
