import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import LazyLoad from 'react-lazy-load';

export default class TutorialPreview extends Component {
  render() {
    return (
      <Link
        key={this.props.tutorial.id}
        to={`/tutorial${this.props.tutorial.alias}/`}
        className={`resource-preview bg-light ${this.props.className} text-decoration-none`}
      >
        <LazyLoad height={278} offsetVertical={300}>
          <div className="resource-preview__image">
            <img
              alt="Map preview"
              src={this.props.tutorial.image.small}
            />
          </div>
        </LazyLoad>
        <div className="p-3 text-dark">
          <h4 className="resource-preview__title" title={this.props.tutorial.title}>
            <Truncate lines={2}>
              {this.props.tutorial.title}
            </Truncate>
          </h4>
        </div>
      </Link>
    );
  }
}

TutorialPreview.propTypes = {
  tutorial: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string.isRequired,
      medium: PropTypes.string.isRequired,
      large: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

TutorialPreview.defaultProps = {
  className: '',
};
