import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Nav from './Nav';
import Home from './Home';
import About from './About';
import Maps from './Maps';
import Services from './Services';
import Contact from './Contact';
import Resource from './Resource';
import Resources from './Resources';
import Tutorial from './Tutorial';
import Tutorials from './Tutorials';
import Privacy from './Privacy';
import Legal from './Legal';
import NoMatch from './NoMatch';
import MapInfoTileFileGenerator from './MapInfoTileFileGenerator';
import ZoomCalculator from './ZoomCalculator';
import OverlapViewer from './OverlapViewer';
import Titler from '../lib/Titler';

class App extends Component {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
    this.unlisten = this.props.history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    return (
      <div className="app d-flex flex-column">
        <Nav />
        <Switch>
          <Route 
            path="/" 
            exact
            component={Titler(Home, 'Home')} 
          />
          <Route 
            path="/about/" 
            exact
            component={Titler(About, 'About')} 
          />
          <Route 
            path="/maps/" 
            exact
            component={Titler(Maps, 'Maps')} 
          />
          <Route 
            path="/services/" 
            exact
            component={Titler(Services, 'Services')} 
          />
          <Route 
            path="/contact/" 
            exact
            component={Titler(Contact, 'Contact')} 
          />
          <Route 
            path="/resource/:alias/" 
            exact
            component={Titler(Resource, 'Resource')} 
          />
          <Route 
            path="/resources/" 
            exact
            component={Titler(Resources, 'Resources')} 
          />
          <Route 
            path="/tutorial/:alias/" 
            exact
            component={Titler(Tutorial, 'Tutorial')} 
          />
          <Route 
            path="/tutorials/" 
            exact
            component={Titler(Tutorials, 'Tutorials')} 
          />
          <Route 
            path="/privacy/" 
            exact
            component={Titler(Privacy, 'Privacy')} 
          />
          <Route 
            path="/legal/" 
            exact
            component={Titler(Legal, 'Legal')} 
          />
          <Route 
            path="/tool/map-info-tile-file-generator/" 
            exact
            component={Titler(MapInfoTileFileGenerator, 'MapInfo tile file generator | Tool')} 
          />
          <Route 
            path="/tool/zoom-calculator/" 
            exact
            component={Titler(ZoomCalculator, 'Zoom calculator | Tool')} 
          />
          <Route 
            path="/tool/overlap-viewer/" 
            exact
            component={Titler(OverlapViewer, 'Overlap viewer | Tool')} 
          />
          <Route 
            component={Titler(NoMatch, '404 | Not Found')} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(App);
