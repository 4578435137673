import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

export default class Download extends Component {
  render() {
    return (
      <span className="bg-white d-inline-block">
        <a
          className="btn btn-outline-info"
          onClick={() => {
            ReactGA.event({
              category: 'Resource',
              action: 'Download',
              label: this.props.label,
            });
          }}
          rel="nofollow"
          download
          href={this.props.url}
        >
          Download
        </a>
      </span>

    );
  }
}

Download.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
