import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDisqusComments from 'react-disqus-comments';
import axios from 'axios';
import ReactGA from 'react-ga';
import Purchase from './Purchase';
import Download from './Download';
import Heading from './Heading';
import NoMatch from './NoMatch';
import ImageModal from './ImageModal';

export default class Resource extends Component {
  state = {
    resource: null,
    noMatch: false,
    imageModalOpen: false,
  }
  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/resource/${this.props.match.params.alias}`,
    })
      .then((response) => {
        document.title = `${response.data.title} | Resource | Orr & Associates`;
        this.setState({ resource: response.data });
      })
      .catch(() => {
        this.setState({ noMatch: true });
      });
  }
  render() {
    const resource = this.state.resource;
    if (this.state.noMatch) {
      return (<NoMatch />);
    } else if (!resource) {
      return null;
    }
    const tagImages = resource.tags.map((tag) => {
      switch (tag) {
        case 'MapInfo':
          return (
            <div className="pt-1" key={tag}>
              <img className="resource__tag" src="/MapInfo.jpg" alt={tag} />
              <p>Map Info files included.</p>
            </div>
          );
        case 'ArcView':
          return (
            <div className="pt-1" key={tag}>
              <img className="resource__tag" src="/ArcGIS.jpg" alt={tag} />
              <p>ArcGIS files included. Please get in contact to find out if we support your version.</p>
            </div>
          );
        default:
          return null;
      }
    });
    return (
      <div className="resource container">
        {this.state.imageModalOpen &&
          <ImageModal
            onClose={() => {
              this.setState({ imageModalOpen: false });
            }}
            image={resource.image}
            name={resource.title}
          />
        }
        <Heading>{resource.title}</Heading>
        <div className="row pb-5">
          <div className="col-sm-6">
            <h4 className="pt-3">Description</h4>
            <div
              className="resource__description"
              dangerouslySetInnerHTML={{
                __html: resource.description ? resource.description.replace(/\/sites\/default\/files/g, `${process.env.REACT_APP_BACKEND_URL}/sites/default/files`) : '',
              }}
            />
            <h4 className="pt-3">Attribution</h4>
            {resource.attribution}
            <h4 className="pt-3">Scale</h4>
            {resource.scale}
            {resource.tags.length > 0 &&
              <div>
                <h4 className="pt-3">Software format</h4>
                {tagImages}
                <div className="pt-1">
                  <img className="resource__tag" src="/QGIS.png" alt="QGIS" />
                  <p>QGIS compatible. QGIS can open MapInfo files but styles are not interpreted.</p>
                </div>
              </div>
            }
          </div>
          <div className="col-sm-6">
            <img
              src={resource.image.medium}
              alt={`Preview of ${resource.title}`}
              className="resource__preview img-thumbnail"
              onClick={() => {
                this.setState({ imageModalOpen: true });
                ReactGA.event({
                  category: 'Resource',
                  action: 'Preview',
                  label: resource.title,
                });
              }}
              title={`Preview of ${resource.title}`}
            />
          </div>
        </div>
        <hr />
        {!resource.available && <h3>Coming soon!</h3>}
        {resource.available && resource.price === '0.00' &&
          <Download label={resource.title} url={`${process.env.REACT_APP_BACKEND_URL}/ob/download/${resource.id}`} />
        }
        {resource.available && resource.price !== '0.00' &&
          <Purchase resource={resource} />
        }
        <hr />
        <ReactDisqusComments
          shortname={process.env.REACT_APP_DISQUS_SHORTNAME}
          identifier={resource.id}
          className="pt-5"
          url={`${window.location.protocol}//${window.location.host}/${this.props.match.params.alias}/`}
          title={resource.title}
          onNewComment={() => {
            ReactGA.event({
              category: 'Resource',
              action: 'Comment',
              label: resource.title,
            });
          }}
        />
      </div>
    );
  }
}

Resource.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      alias: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
