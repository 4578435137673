import React, { Component } from 'react';
import classNames from 'classnames';
import Heading from './Heading';

const containsAll = (string, things) => {
  return things.every(thing => string.indexOf(thing) >= 0);
}

class Field extends Component {
  state = {
    showError: false,
  }
  render() {
    return (
      <>
        <label className="col-2 col-form-label">{this.props.label}</label>
        <div className={`col-${this.props.col}`}>
          <input 
            className={classNames('form-control', { 'is-invalid': this.state.showError && this.props.error })}
            type={this.props.type} 
            value={this.props.value} 
            onChange={this.props.onChange}
            onBlur={() => this.setState({ showError: true })}
            onFocus={() => this.setState({ showError: false })}
            placeholder={this.props.placeholder}
          />       
          {this.state.showError && 
            <div className="invalid-tooltip">
              {this.props.error}
            </div>
          }
        </div>
      </>
    )
  }
}

export default class MapInfoTileFileGenerator extends Component {
  state = {
    name: '',
    url: '',
    minZoom: 0,
    maxZoom: 18,
  }
  generateTab = () => {
    return `!table
!version 1050
!charset WindowsLatin1


Definition Table
  File "${this.state.name}.xml"
  Type "TILESERVER"
  CoordSys Earth Projection 10, 157, 7, 0 Bounds (-20037508.3427892, -20037508.3427892)
      (20037508.3427892, 20037508.3427892)
ReadOnly
`
  }
  generateXml = () => {
    const url = this.state.url
      .replace('{z}', '{LEVEL}')
      .replace('{x}', '{ROW}')
      .replace('{y}', '{COL}')
      .replace(/&/g, "&amp;")
      .replace(/>/g, "&gt;")
      .replace(/</g, "&lt;")
      .replace(/"/g, "&quot;");
    return `<?xml version="1.0" encoding="utf-8"?>
<TileServerInfo Type="LevelRowColumn">
  <Url>${url}</Url>
  <MinLevel>${this.state.minZoom}</MinLevel>
  <MaxLevel>${this.state.maxZoom}</MaxLevel>
  <TileSize Height="256" Width="256" />
</TileServerInfo>
`
  }
  render() {
    const errors = {};

    if (!this.state.name) {
      errors.name = 'Please enter in a name';
    }

    if (!this.state.url) {
      errors.url = 'Please enter in a url';
    }
    else if (!(containsAll(this.state.url, [ '{x}', '{y}', '{z}' ]) || containsAll(this.state.url, [ '{ROW}', '{COL}', '{LEVEL}' ]))) {
      errors.url = 'Make sure the URL has {x}, {y} and {z} parameters.';
    }
    else if (!this.state.url.startsWith('http')) {
      errors.url = 'Make sure the URL starts with the protocol (eg. http).';
    }
    if (this.state.minZoom < 0) {
      errors.minZoom = 'Please enter in a number greater than or equal to zero.';
    }
    else if (this.state.minZoom > this.state.maxZoom) {
      errors.minZoom = 'Make sure the min zoom is smaller than max zoom.';
    }

    if (this.state.maxZoom < 0) {
      errors.maxZoom = 'Please enter in a number greater than or equal to zero.';
    }
    return (
      <div className="container">        
        <Heading>MapInfo tile file generator</Heading>
        <div className="row">
          <div className="col-8 offset-2">
            <div className="form-group row">
            <Field 
              label="Name"
              col="10"
              type="text"
              value={this.state.name}
              error={errors.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              placeholder="Bobs Tiles"
            />
            </div>
            <div className="form-group row">
              <Field 
                label="URL"
                col="10"
                type="text"
                value={this.state.url}
                error={errors.url}
                onChange={(e) => this.setState({ url: e.target.value })}
                placeholder="https://example.com/tiles/{z}/{x}/{y}.png"
              />
            </div>
            <div className="form-group row">
              <Field 
                label="Min Zoom"
                col="4"
                type="number"
                value={this.state.minZoom}
                error={errors.minZoom}
                onChange={(e) => this.setState({ minZoom: parseInt(e.target.value) })}
              />
              <Field 
                label="Max Zoom"
                col="4"
                type="number"
                value={this.state.maxZoom}
                error={errors.maxZoom}
                onChange={(e) => this.setState({ maxZoom: parseInt(e.target.value) })}
              />
            </div>
            {Object.keys(errors).length === 0 &&
              <div className="row pt-5">
                <p className="col">
                  Download the following two files into the same directory and then open up the .TAB file in MapInfo.
                </p>
                <div className="col">
                  <a 
                    className="btn btn-info"
                    href={`data:text/plain;charset=utf-8,${encodeURIComponent(this.generateTab())}`}
                    download={`${this.state.name}.TAB`}
                  >
                    TAB
                  </a>
                  {' '}
                  <a 
                    className="btn btn-info"
                    href={`data:text/plain;charset=utf-8,${encodeURIComponent(this.generateXml())}`}
                    download={`${this.state.name}.xml`}
                  >
                    XML
                  </a>
                </div>
              </div>
            }
          </div>
        </div>                
      </div>
    );
  }
}