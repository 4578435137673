import React, { Component } from 'react';
import Heading from './Heading';

const services = [
  {
    name: 'data bureau',
    description: 'Orr and Associates specialise in helping organisations make the transition from hardcopy to digital databases. We are able to help with any type of tabular data set. We are also able to offer fast turnaround and can work at very competitive rates. Ask us for a quote.',
    icon: 'fas fa-database',
  },
  {
    name: 'digitising bureau',
    description: 'We also operate a digitising bureau devoted to the capture of all types of map data (soil, geology, regolith, topography, cadastral maps). We capture both gridded and random point style data from maps such as soil sample grids and stream sediment programs. We can guarantee that if you are doing this sort of work in house it is costing you too much. We use rigorous quality control systems and all work is checked in detail for errors and logic by our professional staff. Our rates are very competitive, contact us for a quote.',
    icon: 'fas fa-globe',
  },
  {
    name: 'specialist geological services',
    description: 'We specialise in the sourcing and cost effective compilation of geotechnical data as well as its storage, management and interpretation. We are able to conduct training tailored to the needs of geoscientists, designed to give them the tools to work effectively in the digital environment. Our primary GIS software is MapInfo. Other areas of expertise include GIS based project generative studies and the processing of large regional geochemical data sets, as well as the building of environmental GIS for the management of rehabilitation work.',
    icon: 'fas fa-map-marker-alt',
  },
  {
    name: 'digital geological maps',
    description: 'If you are looking for a digital version of a geological map please let us know. We have a large collection of scanned geological maps from around the world, on most of which we have done the initial digitising. If we do not have it we will do our best to help you find it, if what you need only exists as hardcopy we will help you create the digital data set you need.',
    icon: 'fas fa-street-view',
  },
  {
    name: 'general gis services',
    description: 'We are able to provide a range of services such as warping raster and vector files, image processing, database design and management. We can also arrange the scanning and georeferencing of both large and small map collections. We can manage your data and provide analysis and output as required. Contact us for a quote.',
    icon: 'fas fa-info-circle',
  },
];

export default class Services extends Component {
  render() {
    const list = services.map((service) => {
      return (
        <div className="d-flex align-items-center row pt-5 pb-5" key={service.name}>
          <div className="col-sm-4 text-center text-sm-right text-uppercase">
            <h4 className="pb-4 mb-0">{service.name}</h4>
            <i className={`text-info fa-5x ${service.icon}`} />
          </div>
          <p className="col m-0 p-4">{service.description}</p>
        </div>
      );
    });
    return (
      <div className="services container mb-5">
        <Heading>Services</Heading>
        {list}
      </div>
    );
  }
}
