import React, { Component } from 'react';
import Heading from './Heading';

export default class About extends Component {
  render() {
    return (
      <div className="about container">
        <Heading>About</Heading>
        <div className="row">
          <div className="col-md">
            <h3>History</h3>
            <p>
              Tom and Lisa Orr started up Orr and Associates (aka Orrbodies) in 1998 to provide specialist services to the Resource Industry.
            </p>
            <p>
              Prior to this Tom and Lisa, both geologists, worked for a combined nearly 40 years in the Mining and Exploration Industries working in countries such as New Zealand, Australia (Queensland, Victoria, Northern Territory, Western Australia) Zimbabwe, Namibia, Indonesia, Korea, Myanmar and the Philippines. Work experience ranged from greenfields exploration in Africa (Greenstone), both mine based and greenfields exploration in Queensland (Porphyry Breccia, Epithermal), gold exploration in Victoria (Carlin Style), basemetals projects in northern western Australia (Mississippi Valley), oil and gas exploration in Southern Queensland and a special projects role in South East Asia (Gold).
            </p>
            <p>
              During this time they also completed their Masters Degrees at Victoria University (Wellington, New Zealand) working in the Torlesse rocks of the Tararua ranges studying the complexities of subduction.
            </p>
            </div>
          <div className="col-md">
            <h3>Present</h3>
            <p>
              In more recent years the business has grown in its scope to include a wider range of industries including volunteering their services for local environmental projects. Orr and Associates have supplied digital data sets to all of the major mining companies as well as numerous and varied organisations such as the USGS, AGSO, BRGM, US National Hurricane Center, and French Red Cross to name a few.
            </p>
            <p>
              As well as this, their growing collection of free digital datasets has become very popular with Universities and students.
            </p>
            <p>
              Currently the core areas of work include:
            </p>
            <ul>
              <li>Compilation of digital databases from various forms of hardcopy regional and mine based exploration data.</li>
              <li>Production of digital geological maps from hardcopy</li>
              <li>Processing of geochemical data (levelling and imaging)</li>
              <li>Prospectivity mapping</li>
            </ul>
          </div>
        </div>
        <p className="text-center mt-5">
          <i className="text-info fab fa-linkedin" />{' '}
          Both{' '}
          <a
            href="https://www.linkedin.com/in/thomas-orr-a7767813/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tom{' '}
          </a>
            and{' '}
          <a
            href="https://www.linkedin.com/in/lisa-orr-0b5a4654/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lisa{' '}
          </a>
          are both on Linkedin so go ahead and check them out there.
        </p>
      </div>
    );
  }
}
