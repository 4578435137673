import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import $ from 'jquery';
import Subscribe from './Subscribe';

export default class Nav extends Component {
  collapse = () => {
    $(this.navbar).collapse('hide');
  }
  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-light bg-white">
        <Link className="navbar-brand nav__title" to="/">Orr &amp; Associates</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div ref={(e) => { this.navbar = e; }} className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto text-lowercase">
            <li className="nav-item">
              <Link activeClassName="active" onClick={this.collapse} className="nav-link" to="/resources/">Resources</Link>
            </li>
            <li className="nav-item">
              <Link activeClassName="active" onClick={this.collapse} className="nav-link" to="/maps/">Maps</Link>
            </li>
            <li className="nav-item">
              <Link activeClassName="active" onClick={this.collapse} className="nav-link" to="/services/">Services</Link>
            </li>
            <li className="nav-item">
              <Link activeClassName="active" onClick={this.collapse} className="nav-link" to="/tutorials/">Tutorials</Link>
            </li>
            <li className="nav-item">
              <Link activeClassName="active" onClick={this.collapse} className="nav-link" to="/about/">About</Link>
            </li>
            <li className="nav-item">
              <Link activeClassName="active" onClick={this.collapse} className="nav-link" to="/contact/">Contact</Link>
            </li>
          </ul>
        </div>
        <Subscribe className="d-none d-md-block" />
      </nav>
    );
  }
}
