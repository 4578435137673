import React, { Component } from 'react';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import Recaptcha from 'react-google-recaptcha';
import axios from 'axios';
import Heading from './Heading';

export default class Contact extends Component {
  state = {
    wasValidated: false,
    hasSubmitted: false,
    recaptcha: '',
  };
  submit = () => {
    const data = {
      name: this.name.value,
      email: this.email.value,
      message: this.message.value,
      recaptcha: this.state.recaptcha,
    };
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/contact`,
      data,
    })
      .then((response) => {
        if (response.data.success) {
          ReactGA.event({
            category: 'Contact',
            action: 'Online form submitted',
          });
        }
      });
    this.setState({ hasSubmitted: true });
  }
  render() {
    return (
      <div className="contact container">
        <Heading>Contact</Heading>
        <div className="card mb-5">
          <div className="card-header">
            <i className="text-info fa fa-comment" /> Online
          </div>
          {this.state.hasSubmitted ?
            <div className="card-body">
              Thanks, we will get back to you soon.
            </div>
            :
            <div className="card-body">
              <form
                ref={(e) => { this.form = e; }}
                className={classNames('needs-validation', {
                  'was-validated': this.state.wasValidated,
                })}
                noValidate
              >
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="your name"
                    ref={(e) => { this.name = e; }}
                  />
                  <div className="invalid-feedback">
                    Please enter in your name.
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    required
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                    ref={(e) => { this.email = e; }}
                  />
                  <div className="invalid-feedback">
                    Please enter in your email.
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    required
                    className="form-control"
                    id="message"
                    rows={10}
                    ref={(e) => { this.message = e; }}
                  />
                  <div className="invalid-feedback">
                    Please enter in a message.
                  </div>
                </div>
                <div className="form-group">
                  <Recaptcha
                    sitekey="6LcwVEQUAAAAAH43y1M_FY_F2jStzd9kkfAy7Nud"
                    render="explicit"
                    onChange={(e) => {
                      this.setState({ recaptcha: e });
                    }}
                    onExpired={() => {
                      this.setState({ recaptcha: '' });
                    }}
                  />
                  <div
                    className={classNames('invalid-feedback', {
                      'd-block': !this.state.recaptcha && this.state.wasValidated,
                    })}
                  >
                    Please confirm you are human.
                  </div>
                </div>
                <button
                  className="btn btn-outline-info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (this.form.checkValidity() && this.state.recaptcha) {
                      this.submit();
                    } else {
                      this.setState({ wasValidated: true });
                    }
                  }}
                >
                  Send
                </button>
              </form>
            </div>
          }
        </div>

        <div className="card mb-5">
          <div className="card-header">
            <i className="text-info fa fa-phone" /> Phone
          </div>
          <div className="card-body">
            <p>
              within australia:
              <a
                href="tel:+61-07-4068-8692"
                onClick={() => {
                  ReactGA.event({
                    category: 'Contact',
                    action: 'Phone link pressed',
                    label: 'Within Australia',
                  });
                }}
              >
                (07) 4068 8692
              </a>
              <br />
              international:
              <a
                href="tel:+61-07-4068-8692"
                onClick={() => {
                  ReactGA.event({
                    category: 'Contact',
                    action: 'Phone link pressed',
                    label: 'International',
                  });
                }}
              >
                +61 7 4068 8692
              </a>
            </p>
          </div>
        </div>

        <div className="card mb-5">
          <div className="card-header">
            <i className="text-info fa fa-envelope" /> Post
          </div>
          <div className="card-body">
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Contact',
                  action: 'Postal address pressed',
                });
              }}
              href="https://www.google.com/maps/search/?api=1&query=4+Wildsoet+St,+Wongaling+Beach+QLD+4852"
            >
              <p>
                4 Wildsoet Street
                <br />
                Wongaling Beach
                <br />
                Queensland 4852
                <br />
                Australia
              </p>
            </a>
          </div>
        </div>

      </div>
    );
  }
}
