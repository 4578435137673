import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import '../scss/main.scss';
import Leaflet from 'leaflet';
import App from '../components/App';

ReactGA.initialize('UA-113323615-1');
Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/';

const Root = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default Root;
