import React, { Component } from 'react';
import classNames from 'classnames';
import Heading from './Heading';

export default class OverlapViewer extends Component {
  state = {
    side: 0,
    front: 0
  }
  imageWidth = 100
  imageHeight = 75
  render() {
    const overlapping = ( 1 / (1 - this.state.side / 100) ) * ( 1 / (1 - this.state.front / 100) )
    const Images = React.memo(() => {
      const items = [];
      for (let i = 0; i < 1000; i += 1) {
        items.push(
          <div 
            key={i} 
            className="overlap__image border border-info" 
          />
        );
      }
      return (
        <div className="overlap__images d-flex align-items-start justify-content-center">
          {items}  
        </div>
      );
    });    
    return (
      <div className="container">        
        <Heading>Overlap Viewer</Heading>
        <div className="row">
          <div className="col-8 offset-2">
            <div className="form-group row">
              <label className="col-3 col-form-label">Side</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={this.state.side}
                  onChange={e => this.setState({ side: parseInt(e.target.value) })}
                />       
              </div>
            </div> 
            <div className="form-group row">
              <label className="col-3 col-form-label">Front</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={this.state.front} 
                  onChange={e => this.setState({ front: parseFloat(e.target.value) })}
                />       
              </div>
            </div>                
            <div className="form-group row">
              <label className="col-3 col-form-label">Overlapping images</label>
              <div className="col-9">
                <input 
                  className={classNames('form-control')}
                  type="number"
                  value={overlapping.toFixed(2)} 
                  disabled
                />       
              </div>
            </div>         
          </div>              
        </div>  
        <Images />
        <style>
          {`
          .overlap__images {
            padding-right: ${this.imageWidth * this.state.side / 100}px;
            padding-bottom: ${this.imageHeight * this.state.front / 100}px;
          }
          .overlap__image {
            height: ${this.imageHeight}px;
            width: ${this.imageWidth}px;
            margin-right: -${this.imageWidth * this.state.side / 100}px;
            margin-bottom: -${this.imageHeight * this.state.front / 100}px;
          }
          `}
        </style>
      </div>
    );
  }
}