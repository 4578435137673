import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import LazyLoad from 'react-lazy-load';
import { priceGenerator } from '../lib/utils';

export default class ResourcePreview extends Component {
  render() {
    const price = priceGenerator(this.props.resource);
    return (
      <Link
        key={this.props.resource.id}
        to={`/resource${this.props.resource.alias}/`}
        className={`resource-preview bg-light ${this.props.className} text-decoration-none`}
      >
        <LazyLoad height={278} offsetVertical={300}>
          <div className="resource-preview__image">
            <img
              alt="Map preview"
              src={this.props.resource.image.small}
            />
          </div>
        </LazyLoad>
        <div className="p-3">
          <div className="row mb-2">
            <div className="col">
              <span className="badge badge-info">
                {this.props.resource.type}
              </span>
            </div>
            <div className="col text-success text-right">
              {price}
            </div>
          </div>
          <h4 className="resource-preview__title text-dark mb-0" title={this.props.resource.title}>
            <Truncate lines={2}>
              {this.props.resource.title}
            </Truncate>
          </h4>
        </div>
      </Link>
    );
  }
}

ResourcePreview.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string.isRequired,
      medium: PropTypes.string.isRequired,
      large: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

ResourcePreview.defaultProps = {
  className: '',
};
