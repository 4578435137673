import numeral from 'numeral';

export function priceGenerator(resource) {
  let price;
  if (!resource.available) {
    price = 'Price TBA';
  } else if (resource.price === '0.00') {
    price = 'FREE';
  } else {
    price = numeral(resource.price).format('$0,0.00');
  }
  return price;
}
