import React, { Component } from 'react';
import { Map, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import classNames from 'classnames';
import axios from 'axios';
import PropTypes from 'prop-types';
import ResourcePreview from './ResourcePreview';
import { priceGenerator } from '../lib/utils';

export default class Maps extends Component {
  state = {
    active: null,
    resources: [],
    visibleResources: [],
  }
  componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/ob/resources`,
    })
      .then((response) => {
        const resources = response.data;
        this.setState({ resources, visibleResources: resources });
      });
  }
  markers = {}
  render() {
    const list = this.state.visibleResources.map((resource) => {
      const price = priceGenerator(resource);
      return (
        <a
          key={resource.id}
          onClick={() => {
            this.markers[resource.id].leafletElement.openPopup();
          }}
          className={classNames('rounded-0 maps__list-item list-group-item list-group-item-action', {
           active: this.state.active === resource.id,
          })}
        >
          <div className="w-100 d-flex justify-content-between">
            <span className="badge badge-info">{resource.type}</span>
            <small className="text-success">{price}</small>
          </div>
          <p className="pt-1 mb-1">{resource.title}</p>
        </a>
      )
    });
    const markers = this.state.resources.map((resource) => (
      <Marker
        key={resource.id}
        position={resource.position}
        autoPan
        ref={e => this.markers[resource.id] = e}
        onPopupOpen={() => {
          this.setState({ active: resource.id });
        }}
        onPopupClose={() => {
          this.setState({ active: null });
        }}
      >
        <Popup className="maps__popup" minWidth={280} maxWidth={280} width={280} autoPan>
          {this.state.active === resource.id &&
            <ResourcePreview resource={resource} />
          }
        </Popup>
      </Marker>
    ));
    return (
      <div className="maps container-fluid p-0 d-flex flex-column">
        <div className="d-flex align-items-stretch h-100">
          <div className="maps__list d-none d-sm-block">
            <div className="list-group">
              {list}
            </div>
          </div>
          <Map
            className="maps__map h-100"
            center={[0, 50]}
            zoom={2.5}
            zoomControl={false}
            worldCopyJump
            dragging
            onViewportChanged={() => {
              if (this.map) {
                const resources = this.state.resources;
                const visibleResources = resources.filter(resource =>
                  resource.position[0] && resource.position[1] && this.map.leafletElement.getBounds().contains(resource.position)
                );
                this.setState({ visibleResources });
              }
            }}
            ref={e => this.map = e}
          >
            <TileLayer
              attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
              url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ZoomControl position="topright" />
            {markers}
          </Map>
        </div>
      </div>
    );
  }
}

Maps.contextTypes = {
  router: PropTypes.object,
};
