import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dropin from 'braintree-web-drop-in';
import axios from 'axios';
import classNames from 'classnames';
import numeral from 'numeral';
import ReactGA from 'react-ga';
import Recaptcha from 'react-google-recaptcha';
import Download from './Download';

export default class Purchase extends Component {
  state = {
    showPayButton: false,
    wasValidated: false,
    hasPaid: false,
    url: '',
    error: false,
    recaptcha: '',
  }
  componentDidMount() {
    dropin.create(
      {
        authorization: process.env.REACT_APP_BRAINTREE_AUTHORIZATION,
        container: this.container,
        paypal: {
          button: {
            type: 'checkout',
          },
        },
      },
      (createErr, instance) => {
        if (createErr) {
          console.error(createErr);
        } else {
          this.instance = instance;
          this.setState({ showPayButton: true });
        }
      },
    );
  }
  submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.form.checkValidity()) {
      this.instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
        if (!requestPaymentMethodErr) {
          const recaptcha = this.state.recaptcha;
          this.recaptcha.reset();
          this.setState({ showPayButton: false, recaptcha: '' });
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/ob/purchase`,
            data: {
              nonce: payload.nonce,
              id: parseInt(this.props.resource.id, 10),
              name: this.name.value,
              email: this.email.value,
              message: this.message.value,
              address: this.address.value,
              recaptcha,
            },
          })
            .then((response) => {
              this.setState({ showPayButton: false, hasPaid: true, url: response.data.url });
              ReactGA.event({
                category: 'Resource',
                action: 'Purchase',
                label: this.props.resource.title,
              });
            })
            .catch(() => {
              this.setState({ showPayButton: true, error: true });
            });
        }
      });
    } else {
      this.setState({ wasValidated: true });
    }
  }
  render() {
    const resource = this.props.resource;
    if (!resource) {
      return null;
    }
    return (
      <div className="purchase">
        {this.state.hasPaid ?
          <div>
            <div className="alert alert-success" role="alert">
              Thanks! Your payment was successful. Please use the link below to download this resource.
            </div>
            <Download label={resource.title} url={this.state.url} />
          </div>
        :
          <div className="pb-5 pt-5">
            <h2>Purchase</h2>
            <p>
              To Purchase this resource please fill in the form below.<br />
              After successfully purchasing you will be given a link to download a zip folder containing the resource files.
              The link will remain valid for one week.
            </p>
            <h3 className="text-success">{numeral(resource.price).format('$0,0.00')} (AUD)</h3>
            <form
              ref={(e) => { this.form = e; }}
              className={classNames('needs-validation', {
                'was-validated': this.state.wasValidated,
              })}
              noValidate
            >
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter name"
                  ref={(e) => { this.name = e; }}
                />
                <div className="invalid-feedback">
                  Please enter your name.
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  required
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  ref={(e) => { this.email = e; }}
                />
                <div className="invalid-feedback">
                  Please enter your email.
                </div>
                <small id="emailHelp" className="form-text text-muted">We will never share your email with anyone else.</small>
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <textarea
                  className="form-control purchase__address"
                  rows="4"
                  id="address"
                  aria-describedby="addressHelp"
                  placeholder="Enter address"
                  ref={(e) => { this.address = e; }}
                />
                <small id="addressHelp" className="form-text text-muted">(Optional).</small>
              </div>
              <div className="form-group">
                <label className="form-check-label" htmlFor="extra">Is there anything we did not have that you are after?</label>
                <textarea
                  className="form-control"
                  id="extra"
                  aria-describedby="extraHelp"
                  placeholder="I'm after..."
                  ref={(e) => { this.message = e; }}
                />
                <small id="extraHelp" className="form-text text-muted">(Optional).</small>
              </div>
            </form>
            <div className="mb-3" ref={(e) => { this.container = e; }} />
              <div className="mb-3">
                <Recaptcha
                  sitekey="6LcwVEQUAAAAAH43y1M_FY_F2jStzd9kkfAy7Nud"
                  render="explicit"
                  onChange={(e) => {
                    this.setState({ recaptcha: e });
                  }}
                  onExpired={() => {
                    this.setState({ recaptcha: '' });
                  }}
                  ref={e => this.recaptcha = e}
                />
                <div
                  className={classNames('invalid-feedback', {
                    'd-block': !this.state.recaptcha && this.state.wasValidated,
                  })}
                >
                  Please confirm you are human.
                </div>
              </div>
              {this.state.showPayButton && !!this.state.recaptcha &&
                <span className="bg-white d-inline-block">
                  <button
                    className="btn btn-outline-success"
                    onClick={this.submit}
                  >
                    Pay now
                  </button>
                </span>
              }
            {!this.state.showPayButton && !!this.state.recaptcha &&
              <div className="text-center">
                <i className="fas fa-spinner fa-2x fa-pulse" />
              </div>
            }
            {this.state.error &&
              <div className="alert alert-danger mt-2" role="alert">
                Oops something went wrong. Please get in contact with us.
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

Purchase.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};
