import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

export default class ImageModal extends React.Component {
  componentDidMount() {
    $(this.modal).modal({ show: true });
    $(this.modal).on('hidden.bs.modal', () => {
      $(this.modal).modal('dispose');
      this.props.onClose();
    });
  }
  render() {
    return (
      <div
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        ref={(e) => { this.modal = e; }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-fluid" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Preview of {this.props.name}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <img className="image-modal" src={this.props.image.large} alt={`Preview of ${this.props.name}`} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    small: PropTypes.string.isRequired,
    medium: PropTypes.string.isRequired,
    large: PropTypes.string.isRequired,
  }).isRequired,
};
